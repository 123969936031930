<!-- NOT USED -->
<template>
    <v-form :model="isValid" ref="userProfileForm">
        <div class="grid grid-cols-10 gap-x-4 mb-0 w-full">
            <v-text-field
                v-model="userProfileForm.firstName"
                label="First name"
                class="col-span-4"
                :rules="[required]"
            ></v-text-field>
            <v-text-field
                v-model="userProfileForm.lastName"
                label="Last name"
                class="col-span-4"
                :rules="[required]"
            ></v-text-field>
        </div>
        <div class="grid grid-cols-2 gap-x-4 mb-0 w-full">
            <div class="col-span-1">
                <PhoneNumber
                    name="phone"
                    v-model="userProfileForm.phone"
                    ref="phone"
                    :required="true"
                    :validPhone="true"
                />
            </div>
        </div>
    </v-form>
</template>

<script>
import PhoneNumber from '@/components/shared/PhoneNumber'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'UserProfile',
    components: {PhoneNumber},
    mixins: [FormRules],
    props: {
        value: Object,
        default: () => {}
    },
    data() {
        return {
            isValid: true
        }
    },
    methods: {
        validate() {
            return Promise.all([this.$refs.phone.validate(), this.$refs.userProfileForm.validate()])
        }
    },
    computed: {
        userProfileForm: {
            get() {
                if (this.value) return this.value
                return {}
            },
            set(userProfileForm) {
                this.$emit('input', userProfileForm)
            }
        }
    }
}
</script>
