<template>
    <div>
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Profile Image</label>
        <span class="text-display text-xs">Your profile image will display on assessments.</span>

        <div class="flex flex-row w-full items-start ml-0 mt-3">
            <el-upload
                    class=""
                    action=""
                    :before-remove="beforeRemove"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :http-request="httpRequestHandler"
                    disabled
            >
                <div class="rounded bg-primary-light w-20 h-20 rounded-full mr-3 flex items-center justify-center inline-block relative float-left">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <div class=" inline-block relative float-left" v-else>
                        <p class="text-white font-display font-semibold text-3xl pt-0 pl-0">{{ avatar }}</p>
                    </div>
                </div>
                <!-- leaving this here for now -->
<!--                <div class="flex h-full pt-6">-->
<!--                    <el-button class="flex items-center justify-center uppercase primary-lightest font-display text-primary font-bold" size="small" type="primary-lightest">Upload an image</el-button>-->
<!--                </div>-->
            </el-upload>
        </div>
    </div>
</template>

<script>
import ProfileFns from '@/components/shared/mixins/profileFns'

export default {
    name: "ProfilePicture",
    mixins: [ProfileFns],
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            localUser: {},
            imageUrl: '',
        }
    },
    methods: {
        httpRequestHandler(request) {
            this.imageUrl = URL.createObjectURL(request.file)
            this.localUser.avatar = request.file
            this.$emit('updated-profile', this.localUser)
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw)
            this.localUser.avatar = file.raw
            this.$emit('updated-profile', this.localUser)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('Avatar picture must be JPG format!');
            }
            if (!isLt2M) {
                this.$message.error('Avatar picture size can not exceed 2MB!');
            }
            return isJPG && isLt2M;
        },
        uploadHandler(request) {
            this.localUser.avatar = request.file
        },
        beforeRemove(file) {
            return this.$confirm(`Cancel the transfer of ${file.name} ?`)
        },
    },
    computed: {
        avatarImg() {
            return this.localUser.avatar
        }
    },
    watch: {
        user() {
            if (!this.user) this.localUser = this.user
            if (this.localUser.avatar) {
                this.imageUrl = URL.createObjectURL(this.localUser.avatar)
            }
        },
        avatarImg() {
            this.$emit('updated-profile', this.localUser)
        },
        localUser: {
            handler() {
                this.$emit('updated-profile', this.localUser)
            },
            deep: true
        }
    }
}
</script>

